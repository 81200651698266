import { useState, useEffect, useContext } from "react";
import { Button } from 'primereact/button';

import { url, adminAuthorization } from './../lib/lib.js';
import { AdminContext } from './../lib/context.js';
import Invoice from "./Invoice.js";

import './css/login.css';

const Component = ()=> {

    const { setModule } = useContext(AdminContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState({});

    function login(e){

        e.preventDefault();

        setLoading(true);

        fetch(url+'/admin/login', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': adminAuthorization,
                'key': 'J75trTG@jf^%8hT5E',
                'type': 'admin'
            },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
        })
        .then((res)=>res.json())
        .then((data)=>{

            setLoading(false);
            if(data.Status===true){
                setModule(<Invoice />);
            }
            else{
                setNotify({className: "alert alert-danger", msg: "Invalid Credentials"});
                setTimeout(() => {
                    setNotify({className: "", msg: ""});
                  }, 5000);
            }
        })
    }

    return(
        <>  
            <div style={{paddingTop:"100px"}}>
            <form onSubmit={login}>
            <center><h1 style={{fontSize:"20px", paddingTop:"20px"}}><b>Login</b></h1></center>
            <div className="container login">
            
                <label><b>Username</b></label>
                <input type="text" placeholder="Enter Username" onChange={(e)=>{setUsername(e.target.value)}} required />

                <label><b>Password</b></label>
                <input type="password" onChange={(e)=>{setPassword(e.target.value)}} placeholder="Enter Password" required />

                <Button label="Login" loading={loading} />

                <div className={notify.className}>{notify.msg}</div>
            </div>
            </form> 
            
            </div>

        </>
    )
}

export default Component;