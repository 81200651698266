import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Admin from './admin/Admin.js';
import Invoice from './user/Invoice.js';
import Err404 from './lib/Err404.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

function App()
{
    return(
        <>
                <BrowserRouter>
                    <Routes>
                        <Route path='/MiN3627Ad' element={<Admin />} />
                        <Route path='/invoice/:id' element={<Invoice />} />
                        <Route path='*' element={<Err404 />} />
                    </Routes>
                </BrowserRouter>  
        </>
    )
}

root.render(<App />);