import { useState, useEffect, useRef, useContext } from "react";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import { url, adminAuthorization } from '../lib/lib.js';
import { AdminContext } from './../lib/context.js';
import Nav from './Nav.js';
import Login from './Login.js';

const Component = ()=> {

    const { setModule } = useContext(AdminContext);

    const [data, setData] = useState([{}]);
    const [viewLoading, setViewLoading] = useState(false);

    const [addName, setAddName] = useState('');
    const [addAddress, setAddAddress] = useState('');
    const [addGSTIN, setAddGSTIN] = useState('');
    const [addPhone, setAddPhone] = useState('');
    const [addGSTType, setAddGSTType] = useState('CGST & SGST');
    const [addBaseAmount, setAddBaseAmount] = useState('');
    const [addGSTAmount, setAddGSTAmount] = useState('');
    const [addTotalAmount, setAddTotalAmount] = useState('');
    const [addService, setAddService] = useState('');
    const [addInvoiceNo, setAddInvoiceNo] = useState('');
    const [addDate, setAddDate] = useState(new Date().toISOString().slice(0,10));
    const [addPayStatus, setAddPayStatus] = useState('Unpaid');
    const [addDescription, setAddDescription] = useState('');
    const [addNotify, setAddNotify] = useState({});
    const [addLoading, setAddLoading] = useState(false);

    const [editId, setEditId] = useState('');
    const [editName, setEditName] = useState('');
    const [editAddress, setEditAddress] = useState('');
    const [editGSTIN, setEditGSTIN] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editGSTType, setEditGSTType] = useState('');
    const [editBaseAmount, setEditBaseAmount] = useState('');
    const [editGSTAmount, setEditGSTAmount] = useState('');
    const [editTotalAmount, setEditTotalAmount] = useState('');
    const [editService, setEditService] = useState('');
    const [editInvoiceNo, setEditInvoiceNo] = useState('');
    const [editDate, setEditDate] = useState('');
    const [editPayStatus, setEditPayStatus] = useState('');
    const [editDescription, setEditDescription] = useState('');
    const [editNotify, setEditNotify] = useState({});
    const [editLoading, setEditLoading] = useState(false);

    const [deleteNotify, setDeleteNotify] = useState({});
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [sendNotify, setSendNotify] = useState({});
    const [sendLoading, setSendLoading] = useState(false);

    const dt = useRef(null);

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    function fetchData()
    {
        setViewLoading(true);

        fetch(url+'/invoice/view', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': adminAuthorization,
                'key': 'J7b$5LfdIH#@6G#Y4^j9',
                'type': 'admin'
            },
            body: JSON.stringify({
               
            })
        })
        .then((res)=>{
            if(res.ok)
                return res.json();
            else
                setModule(<Login />);
        })
        .then((res)=>{
            
            if(res){
                let data = res.Data.map((arr)=>{
                    arr.base_amount = arr.base_amount.toLocaleString('en-IN');
                    arr.gst_amount = arr.gst_amount.toLocaleString('en-IN');
                    arr.total_amount = arr.total_amount.toLocaleString('en-IN');
                    return arr;
                });
    
                setData(data);
                setViewLoading(false);
            }
        })
    };

    function add(e){

        e.preventDefault();

        setAddLoading(true);

        fetch(url+'/invoice/add', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': adminAuthorization,
                'key': 'J7b$5LfdIH#@6G#Y4^j9',
                'type': 'admin'
            },
            body: JSON.stringify({
                "name": addName,
                "address": addAddress,
                "gstin": addGSTIN,
                "phone": addPhone,
                "gst_type": addGSTType,
                "base_amount": addBaseAmount,
                "gst_amount": addGSTAmount,
                "total_amount": addTotalAmount,
                "service": addService,
                "invoice_no": addInvoiceNo,
                "date": addDate,
                "pay_status": addPayStatus,
                "description": addDescription,
            })
        })
        .then((res)=>{
            if(res.ok)
                return res.json();
            else
                setModule(<Login />);
        })
        .then((data)=>{
           
            setAddLoading(false);

            if(data){
                if(data.Status===true){
                    
                    setAddName('');
                    setAddAddress('');
                    setAddGSTIN('');
                    setAddPhone('');
                    setAddGSTType('CGST & SGST');
                    setAddBaseAmount('');
                    setAddGSTAmount('');
                    setAddTotalAmount('');
                    setAddService('');
                    setAddInvoiceNo('');
                    setAddDate(new Date().toISOString().slice(0,10));
                    setAddPayStatus('Unpaid');
                    setAddDescription('');

                    setAddNotify({className: "alert alert-success", msg: "Success"});
                    fetchData();
                    $("#addModal").modal('hide');
                }
                else{
                    setAddNotify({className: "alert alert-danger", msg: "Some Error Occured"});
                }
                setTimeout(() => {
                    setAddNotify({className: "", msg: ""});
                }, 1000);
            }
            else{
                $("#addModal").modal('hide');
            }
        })
    };

    function edit(e){

        e.preventDefault();

        setEditLoading(true);

        fetch(url+'/invoice/edit', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': adminAuthorization,
                'key': 'J7b$5LfdIH#@6G#Y4^j9',
                'type': 'admin'
            },
            body: JSON.stringify({
                "filters": {
                    "_id": editId
                },
                "data":{
                    "name": editName,
                    "address": editAddress,
                    "gstin": editGSTIN,
                    "phone": editPhone,
                    "gst_type": editGSTType,
                    "base_amount": editBaseAmount,
                    "gst_amount": editGSTAmount,
                    "total_amount": editTotalAmount,
                    "service": editService,
                    "invoice_no": editInvoiceNo,
                    "date": editDate,
                    "pay_status": editPayStatus,
                    "description": editDescription,
                }
            })
        })
        .then((res)=>{
            if(res.ok)
                return res.json();
            else
                setModule(<Login />);
        })
        .then((data)=>{
           
            setEditLoading(false);

            if(data){
                if(data.Status===true){
                    setEditId('');
                    setEditName('');
                    setEditName('');
                    setEditAddress('');
                    setEditGSTIN('');
                    setEditPhone('');
                    setEditGSTType('');
                    setEditBaseAmount('');
                    setEditGSTAmount('');
                    setEditTotalAmount('');
                    setEditService('');
                    setEditInvoiceNo('');
                    setEditDate('');
                    setEditPayStatus('');
                    setEditDescription('');

                    setEditNotify({className: "alert alert-success", msg: "Success"});
                    fetchData();
                    $("#editModal").modal('hide');
                }
                else{
                    setEditNotify({className: "alert alert-danger", msg: "Some Error Occured"});
                }
                setTimeout(() => {
                        setEditNotify({className: "", msg: ""});
                    }, 1000);
            }
            else{
                $("#editModal").modal('hide');
            }
        })
    };

    function del(id){

        setDeleteLoading(true);

        fetch(url+'/invoice/delete', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': adminAuthorization,
                'key': 'J7b$5LfdIH#@6G#Y4^j9',
                'type': 'admin'
            },
            body: JSON.stringify({
                "filters": {
                    "_id": id
                }
            })
        })
        .then((res)=>{
            if(res.ok)
                return res.json();
            else
                setModule(<Login />);
        })
        .then((data)=>{

            setDeleteLoading(false);

            if(data){
                if(data.Status===true){
                    setDeleteNotify({className: "alert alert-success", msg: "Success"});
                    fetchData();
                }
                else{
                    setDeleteNotify({className: "alert alert-danger", msg: "Some Error Occured"});
                }
                setTimeout(() => {
                        setDeleteNotify({className: "", msg: ""});
                    }, 1000);
            }
        })
    };

    function send(id){

        setSendLoading(true);

        fetch(url+'/invoice/send', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': adminAuthorization,
                'key': 'J7b$5LfdIH#@6G#Y4^j9',
                'type': 'admin'
            },
            body: JSON.stringify({
                "filters": {
                    "_id": id
                }
            })
        })
        .then((res)=>{
            if(res.ok)
                return res.json();
            else
                setModule(<Login />);
        })
        .then((data)=>{

            setSendLoading(false);

            if(data){
                if(data.Status===true){
                    setSendNotify({className: "alert alert-success", msg: "Success"});
                }
                else{
                    setSendNotify({className: "alert alert-danger", msg: "Some Error Occured"});
                }
                setTimeout(() => {
                        setSendNotify({className: "", msg: ""});
                    }, 1000);
            }
        })
    }

    const setAdd = () => {

        let randomNum = Math.floor(Math.random() * 10000);
    
        while (randomNum < 1000) {
            randomNum *= 10;
        }

        let dateStr = +Date.now().toString().slice(-5);
        
        let invoice_no = "E"+randomNum+dateStr;

        setAddInvoiceNo(invoice_no);
    };

    const setEdit = (data) => {

        let data1 = {};

        data1.base_amount = data.base_amount.replaceAll(",", "");
        data1.gst_amount = data.gst_amount.replaceAll(",", "");
        data1.total_amount = data.total_amount.replaceAll(",", "");
            
        setEditId(data._id);
        setEditName(data.name);
        setEditAddress(data.address);
        setEditGSTIN(data.gstin);
        setEditPhone(data.phone);
        setEditGSTType(data.gst_type);
        setEditBaseAmount(data1.base_amount);
        setEditGSTAmount(data1.gst_amount);
        setEditTotalAmount(data1.total_amount);
        setEditService(data.service);
        setEditInvoiceNo(data.invoice_no);
        setEditDate(data.date);
        setEditPayStatus(data.pay_status);
        setEditDescription(data.description);
    };

    const setCopy = (data) => {

        let data1 = {};

        data1.base_amount = data.base_amount.replaceAll(",", "");
        data1.gst_amount = data.gst_amount.replaceAll(",", "");
        data1.total_amount = data.total_amount.replaceAll(",", "");
            
        setAddName(data.name);
        setAddAddress(data.address);
        setAddGSTIN(data.gstin);
        setAddPhone(data.phone);
        setAddGSTType(data.gst_type);
        setAddBaseAmount(data1.base_amount);
        setAddGSTAmount(data1.gst_amount);
        setAddTotalAmount(data1.total_amount);
        setAddService(data.service);
        setAddInvoiceNo(data.invoice_no);
        setAddDate(new Date().toISOString().slice(0,10));
        setAddPayStatus('Unpaid');
        setAddDescription('');
    };

    const setDel = (data) => {
    
        const flag = confirm("Do you want to delete "+data.name+"?");
        if(flag){
            del(data._id);
        }
    };

    const setSend = (data) => {
    
        const flag = confirm("Do you want to send the details to "+data.name+"?");
        if(flag){
            send(data._id);
        }
    };

    useEffect(()=>{
        fetchData();
    }, []);

    const calculate = () => {

        document.getElementById("cal").style.display = 'inline-block';

        var tbl = document.getElementsByTagName("table")[0];
        var total = 0, base = 0, gst = 0;
  
            for (let i = 1; i < tbl.rows.length; i++)
            {
                var oCells = tbl.rows.item(i).cells;
                
                if(oCells.length>1)
                {
                    base = base + Number((oCells.item(7).innerHTML).replaceAll(",", ""));
                    gst = gst + Number((oCells.item(8).innerHTML).replaceAll(",", ""));
                    total = total + Number((oCells.item(9).innerHTML).replaceAll(",", ""));
                }
            }
            
            document.getElementById("enterbase").innerHTML = base.toLocaleString('en-IN');
            document.getElementById("entergst").innerHTML = gst.toLocaleString('en-IN');    
            document.getElementById("entertotal").innerHTML = total.toLocaleString('en-IN');   
    };
    
    const addCal = (type, val) => {

        if(addGSTType=="NA")
        {
            if(type=='base')
            {
                setAddGSTAmount(0);
                setAddTotalAmount(val);
            }
            if(type=='total')
            {
                setAddGSTAmount(0);
                setAddBaseAmount(val);
            }
        }
        else
        {
            let base = 0, gst = 0, total = 0;

            if(type=='base')
            {
                base = Number(val);
                gst = (base / 100) * 18;    
                total = base + gst;

                setAddGSTAmount(Math.round((gst + Number.EPSILON) * 100) / 100);
                setAddTotalAmount(Math.round((total + Number.EPSILON) * 100) / 100);
            }
            if(type=='total')
            {
                total = Number(val);
                base = (total / 118) * 100;    
                gst = total - base;

                setAddGSTAmount(Math.round((gst + Number.EPSILON) * 100) / 100);
                setAddBaseAmount(Math.round((base + Number.EPSILON) * 100) / 100);
            }
        }         
    };

    const editCal = (type, val) => {

        if(editGSTType=="NA")
        {
            if(type=='base')
            {
                setEditGSTAmount(0);
                setEditTotalAmount(val);
            }
            if(type=='total')
            {
                setEditGSTAmount(0);
                setEditBaseAmount(val);
            }
        }
        else
        {
            let base = 0, gst = 0, total = 0;

            if(type=='base')
            {
                base = Number(val);
                gst = (base / 100) * 18;    
                total = base + gst;

                setEditGSTAmount(Math.round((gst + Number.EPSILON) * 100) / 100);
                setEditTotalAmount(Math.round((total + Number.EPSILON) * 100) / 100);
            }
            if(type=='total')
            {
                total = Number(val);
                base = (total / 118) * 100;    
                gst = total - base;

                setEditGSTAmount(Math.round((gst + Number.EPSILON) * 100) / 100);
                setEditBaseAmount(Math.round((base + Number.EPSILON) * 100) / 100);
            }    
        }   
    };

    const actionTable = (rowData) => {

        return ( <>
        <Button type="button" onClick={()=>{setSend(rowData)}} icon="pi pi-send" loading={sendLoading} style={{marginTop:"0px"}} />
        <Button type="button" onClick={()=>{window.open('/invoice/'+rowData.invoice_no)}} icon="pi pi-file-pdf" style={{marginTop:"10px"}} />
        <Button type="button" onClick={()=>{setCopy(rowData);setAdd();}} icon="pi pi-clone" data-toggle="modal" data-target="#addModal" style={{marginTop:"10px"}} />
        <Button type="button" onClick={()=>{setEdit(rowData)}} icon="pi pi-pencil" data-toggle="modal" data-target="#editModal" style={{marginTop:"10px"}} />
        <Button type="button" onClick={()=>{setDel(rowData)}} icon="pi pi-trash" loading={deleteLoading} style={{marginTop:"10px"}} />
        </>)
    };

    const paginatorLeft = <Button type="button" onClick={()=>{fetchData();}} loading={viewLoading} icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" onClick={() => exportCSV(false)} icon="pi pi-download" text />;

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pay_status: { value: null, matchMode: FilterMatchMode.EQUALS },
        date: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <>            
            <div className="row">
                <div className="col-sm-8" style={{paddingTop:"2.5px"}}>
                    <span style={{fontSize:"20px"}}>Invoice </span>
                    <span id="cal" style={{display: 'none', fontSize: "17px", fontWeight: "normal"}}>
                    <span> (Base Amount: Rs. <span id="enterbase">0</span> /-) </span>
                    <span> (GST Amount: Rs. <span id="entergst">0</span> /-) </span>
                    <span> (Total Amount: Rs. <span id="entertotal">0</span> /-) </span>
                    </span>
                </div>
                <div className="col-sm-4">
                    <input type="text" className="form-control" style={{width: "300px", height: "38px", margin: "0px", fontWeight: "normal"}} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                    
                    <Button icon={PrimeIcons.PLUS} onClick={setAdd} style={{float:"right"}} data-toggle="modal" data-target="#addModal" aria-label="New" />
                    <Button icon="pi pi-calculator" onClick={calculate} style={{float:"right", marginRight:"10px"}} />
                </div>
            </div>
            </>
        );
    };

    const header = renderHeader();

    return(
        <>
            <Nav />

            <div className="container-fluid">
            
            <div className={addNotify.className}>{addNotify.msg}</div>
            <div className={editNotify.className}>{editNotify.msg}</div>
            <div className={deleteNotify.className}>{deleteNotify.msg}</div>
            <div className={sendNotify.className}>{sendNotify.msg}</div>

            <div>
            <DataTable ref={dt} value={data} paginator rows={10}
                showGridlines scrollable scrollHeight="490px" tableStyle={{ minWidth: '50rem' }} 
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorLeft={paginatorLeft} paginatorRight={paginatorRight} emptyMessage="No Data Found."
                filters={filters} loading={viewLoading} header={header}
                globalFilterFields={['name', 'address', 'date', 'pay_status', 'service', 'invoice_no']}
                >
                    <Column style={{color:"black"}} field="invoice_no" header="Invoice No." />
                    <Column style={{color:"black"}} field="name" header="Name" />
                    <Column style={{color:"black"}} field="address" header="Address" />
                    <Column style={{color:"black"}} field="gstin" header="GSTIN" />
                    <Column style={{color:"black"}} field="gst_type" header="GST Type" />
                    <Column style={{color:"black"}} field="date" header="Date" sortable filter />
                    <Column style={{color:"black"}} field="service" header="Service" />
                    <Column style={{color:"black"}} field="base_amount" header="Base Amount" />
                    <Column style={{color:"black"}} field="gst_amount" header="GST Amount" />
                    <Column style={{color:"black"}} field="total_amount" header="Total Amount" sortable />
                    <Column style={{color:"black"}} field="pay_status" header="Status" filter />
                    <Column style={{color:"black"}} field="description" header="Remarks" />
                    <Column style={{width:"0rem"}} field="_id" header="Action" bodyClassName="text-center" body={actionTable} />
            </DataTable>
            </div>

            <div className="modal fade" id="addModal" role="dialog" >

                    <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={add} >
                        <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Add Invoice</h4>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Billing Name</b></label>
                                <input type="text" value={addName} placeholder="Enter Billing Name" onChange={(e)=>{setAddName(e.target.value)}} required />

                                </div>

                                <div className="col-sm-6">

                                <label><b>Customer Phone</b></label>
                                <input type="text" min="0" value={addPhone} placeholder="Enter Customer Phone" onChange={(e)=>{setAddPhone(e.target.value)}} required />

                                </div>

                            </div>
                            
                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Billing Address</b></label>
                                <input type="text" value={addAddress} placeholder="Enter Billing Address" onChange={(e)=>{setAddAddress(e.target.value)}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Billing GSTIN</b></label>
                                <input type="text" value={addGSTIN} placeholder="Enter Billing GSTIN" onChange={(e)=>{setAddGSTIN(e.target.value)}} required />
                            
                                </div>

                            </div>
                            
                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>GST Type</b></label>
                                <select value={addGSTType} onChange={(e)=>{setAddGSTType(e.target.value)}} className="form-control">
                                    <option key="CGST & SGST" value="CGST & SGST">CGST & SGST</option>
                                    <option key="IGST" value="IGST">IGST</option>
                                    <option key="NA" value="NA">NA</option>
                                </select> 
                                </div>

                                <div className="col-sm-6">

                                <label><b>Service</b></label>
                                <input type="text" value={addService} placeholder="Enter Service" onChange={(e)=>{setAddService(e.target.value)}} required />
                            
                                </div>

                            </div>
                            
                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Base Amount</b></label>
                                <input type="text" min="0" step="any" value={addBaseAmount} placeholder="Enter Base Amount" onChange={(e)=>{setAddBaseAmount(e.target.value); addCal('base', e.target.value);}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>GST Amount</b></label>
                                <input type="text" min="0" step="any" value={addGSTAmount} placeholder="Enter GST Amount" onChange={(e)=>{setAddGSTAmount(e.target.value);}} required disabled />
                            
                                </div>

                            </div>
                            
                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Total Amount</b></label>
                                <input type="text" min="0" step="any" value={addTotalAmount} placeholder="Enter Total Amount" onChange={(e)=>{setAddTotalAmount(e.target.value); addCal('total', e.target.value);}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Invoice No.</b></label>
                                <input type="text" value={addInvoiceNo} placeholder="Enter Invoice No." onChange={(e)=>{setAddInvoiceNo(e.target.value)}} required />

                                </div>

                            </div>
                            
                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Billing Date</b></label>
                                <input type="date" value={addDate} placeholder="Enter Billing Date" onChange={(e)=>{setAddDate(e.target.value)}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Payment Status</b></label>
                                <select value={addPayStatus} onChange={(e)=>{setAddPayStatus(e.target.value)}} className="form-control">
                                    <option key="Unpaid" value="Unpaid">Unpaid</option>
                                    <option key="Paid" value="Paid">Paid</option>
                                </select>

                                </div>

                            </div>
                            
                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Description</b></label>
                                <input type="text" value={addDescription} placeholder="Enter Description" onChange={(e)=>{setAddDescription(e.target.value)}} />

                                </div>

                                <div className="col-sm-6">

                                
                                </div>

                            </div>
                            
                            <br />
                            
                            <Button icon="pi pi-check" loading={addLoading} /> <br /><br />
                        </div>
                        <div className="modal-footer">
                        
                        <div className={addNotify.className}>{addNotify.msg}</div>
                        </div>
                        </form>
                    </div>
                    </div>
                
            </div>

            <div className="modal fade" id="editModal" role="dialog" >

                    <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={edit} >
                        <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Edit Invoice</h4>
                        </div>
                        <div className="modal-body">
                            
                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Billing Name</b></label>
                                <input type="text" value={editName} placeholder="Enter Billing Name" onChange={(e)=>{setEditName(e.target.value)}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Customer Phone</b></label>
                                <input type="text" min="0" value={editPhone} placeholder="Enter Customer Phone" onChange={(e)=>{setEditPhone(e.target.value)}} required />

                                </div>

                            </div>

                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Billing Address</b></label>
                                <input type="text" value={editAddress} placeholder="Enter Billing Address" onChange={(e)=>{setEditAddress(e.target.value)}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Billing GSTIN</b></label>
                                <input type="text" value={editGSTIN} placeholder="Enter Billing GSTIN" onChange={(e)=>{setEditGSTIN(e.target.value)}} required />
                            
                                </div>

                            </div>

                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>GST Type</b></label>
                                <select value={editGSTType} onChange={(e)=>{setEditGSTType(e.target.value)}} className="form-control">
                                    <option key="CGST & SGST" value="CGST & SGST">CGST & SGST</option>
                                    <option key="IGST" value="IGST">IGST</option>
                                    <option key="NA" value="NA">NA</option>
                                </select> 
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Service</b></label>
                                <input type="text" value={editService} placeholder="Enter Service" onChange={(e)=>{setEditService(e.target.value)}} required />
                            
                                </div>

                            </div>

                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Base Amount</b></label>
                                <input type="text" min="0" step="any" value={editBaseAmount} placeholder="Enter Base Amount" onChange={(e)=>{setEditBaseAmount(e.target.value); editCal('base', e.target.value);}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>GST Amount</b></label>
                                <input type="text" min="0" step="any" value={editGSTAmount} placeholder="Enter GST Amount" onChange={(e)=>{setEditGSTAmount(e.target.value);}} required disabled />
                            
                                </div>

                            </div>

                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Total Amount</b></label>
                                <input type="text" min="0" step="any" value={editTotalAmount} placeholder="Enter Total Amount" onChange={(e)=>{setEditTotalAmount(e.target.value); editCal('total', e.target.value);}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Invoice No.</b></label>
                                <input type="text" value={editInvoiceNo} placeholder="Enter Invoice No." onChange={(e)=>{setEditInvoiceNo(e.target.value)}} required />

                                </div>

                            </div>

                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Billing Date</b></label>
                                <input type="date" value={editDate} placeholder="Enter Billing Date" onChange={(e)=>{setEditDate(e.target.value)}} required />
                            
                                </div>

                                <div className="col-sm-6">

                                <label><b>Payment Status</b></label>
                                <select value={editPayStatus} onChange={(e)=>{setEditPayStatus(e.target.value)}} className="form-control">
                                    <option key="Unpaid" value="Unpaid">Unpaid</option>
                                    <option key="Paid" value="Paid">Paid</option>
                                </select>

                                </div>

                            </div>

                            <div className="row">
                                
                                <div className="col-sm-6">

                                <label><b>Description</b></label>
                                <input type="text" value={editDescription} placeholder="Enter Description" onChange={(e)=>{setEditDescription(e.target.value)}} />

                                </div>

                                <div className="col-sm-6">

                                <label><b>ID</b></label>
                                <input type="text" value={editId} disabled />

                                </div>

                            </div>

                            <br />

                            <Button icon="pi pi-check" loading={editLoading} /> <br /><br />
                        </div>
                        <div className="modal-footer">
                        
                        <div className={editNotify.className}>{editNotify.msg}</div>
                        </div>
                        </form>
                    </div>
                    </div>
            </div>            

            </div>
        </>
    )
}

export default Component;