import { useState } from 'react';

import { AdminContext } from './../lib/context.js';
import Login from './Login.js';

import { PrimeReactProvider } from "primereact/api";
import Tailwind from "primereact/passthrough/tailwind";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeflex/primeflex.css';                       
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';

const Component = ()=> {

    const [module, setModule] = useState(<Login />);

    return(
        <>
            <AdminContext.Provider value={{ module, setModule }}>

                <PrimeReactProvider value={{ unstyled: true, pt: Tailwind }}>
                
                    <div id="app"> {module} </div>
        
                </PrimeReactProvider>

            </AdminContext.Provider>     
        </>
    )
}

export default Component;