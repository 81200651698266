import { useEffect } from "react";

const Component = () => {

    useEffect(()=>{
        window.location.replace('https://encrobytes.com');
    }, []);
    
}

export default Component;