import { useContext } from "react";

import "tailwindcss/tailwind.css";
import { Menubar } from 'primereact/menubar';

import { AdminContext } from './../lib/context.js';
import Login from "./Login.js";
import Invoice from "./Invoice.js";

const Component = ()=> {

    const { setModule } = useContext(AdminContext);

    function changeModule(data){

        setModule(data);
    }

    const items = [
        {
            label: 'Invoice',
            icon: 'pi pi-fw pi-file',
            command: () => {
                        changeModule(<Invoice />)
            }
        },
        {
            label: 'Logout',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
                        changeModule(<Login />)
            }
        }
    ];

    const start = <img onClick={()=>{changeModule(<Dashboard />)}} alt="logo" src="https://encrobytes.com/img/logo.png" style={{height:"40px", marginRight:"300px"}} className="img1 img-responsive"></img>;
    
    return(
        <>
            <div >
                <Menubar model={items} start={start} />
                <br />
            </div>
        </>
    )
}

export default Component;