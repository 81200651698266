import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { url, userAuthorization } from '../lib/lib.js';

import './css/invoice.css';

const Component = ()=> {

    const [data, setData] = useState('');
    const [flag, setFlag] = useState(false);

    const [GSTPercent, setGSTPercent] = useState(false);

    const { id } = useParams();
    
    function fetchData()
    {
        fetch(url+'/invoice/list/'+id, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'authorization': userAuthorization,
                'type': 'user'
            }
        })
        .then((res)=>{
            if(res.ok)
                return res.json();
            else
            window.location.replace('https://encrobytes.com');
        })
        .then((res)=>{
            
            if(res){
                
                try{

                    if(res.Data[0].invoice_no!=""){
                        let data = res.Data.map((arr)=>{
                            arr.base_amount = arr.base_amount.toLocaleString('en-IN');
                            arr.gst_amount = arr.gst_amount.toLocaleString('en-IN');
                            arr.total_amount = arr.total_amount.toLocaleString('en-IN');
                            return arr;
                        });

                        if(res.Data[0].gst_type=="NA")
                        {
                            res.Data[0].gst_type = "GST";   
                            setGSTPercent("0%");
                        }
                        else
                        {
                            setGSTPercent("18%");
                        }
            
                        setData(data[0]);

                        setFlag(true);
                    }
                    else{
                        window.location.replace('https://encrobytes.com');
                    }
                }
                catch(e){
                    window.location.replace('https://encrobytes.com');
                }
                
            }
        })
        .catch((e)=>{
            window.location.replace('https://encrobytes.com');
        })
    };

    useEffect(()=>{
        fetchData();
    }, []);

    const generatePDF = () => {

        if (typeof window.print === 'function') {
            
            window.print();
          }
    };

    if(flag)
    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-9 col-md-7"></div>
                    <div className="col-xs-2 col-md-2">
                        {
                            data.pay_status=="Paid"
                            ?
                                <button type="button" onClick={generatePDF} className="btn btn-primary" style={{backgroundColor: "#3966AA", margin: "10px", float: "right"}}><i className="fa fa-cloud-download"></i> Download</button>
                            :
                                <button type="button" onClick={()=>{window.location.replace(data.payment_link)}} className="btn btn-primary" style={{backgroundColor: "#3966AA", margin: "10px", float: "right"}}><i className="fa fa-inr"></i> Pay Now</button>

                        }
                    </div>
                    <div className="col-xs-1 col-md-3"></div>
                </div>
            </div>
            
            <div className="maininvoice">

            <div className="col-md-12">   

                <div className="row">

                    <div className="receipt-main col-xs-10 col-sm-10 col-md-6 col-xs-offset-1 col-sm-offset-1 col-md-offset-3">
                        
                        <div className="row">
                            <div className="receipt-header">
                                <div className="col-xs-12 col-sm-12 col-md-6">
                                    <div className="receipt-left">
                                        <img className="img-responsive" alt="encrobytes" src="https://encrobytes.com/img/logo.png" /><br />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 text-right">
                                    <div className="receipt-right">
                                        <h5>Bill From (Tax Invoice):</h5>
                                        <h5>Encrobytes Technologies Pvt. Ltd.</h5>
                                        <p> <i className="fa-solid fa-phone"></i> +91-8448332454</p>
                                        <p> <i className="fa-solid fa-envelope"></i> ping@encrobytes.com </p>
                                        <p> <i className="fa-solid fa-location-dot"></i> Plot 5B, Sector 15A, Faridabad, 121007 </p>
                                        <p> <i className="fa-solid fa-file-invoice"></i> GSTIN: 06AAFCE3515L1ZO </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="receipt-header receipt-header-mid">
                                <div className="col-xs-6 col-sm-6 col-md-7 text-left">
                                    <div className="receipt-right" >
                                        <h5>Bill To:</h5>
                                        <p><b>Name:</b> {data.name} </p>
                                        <p><b>Address:</b> {data.address} </p>
                                        <p><b>GSTIN:</b> {data.gstin}</p>
                    
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-5">
                                    <div className="receipt-left">
                                        <b>Invoice No.:</b> {data.invoice_no}
                                            <p><b>Date:</b> {data.date}</p>
                                            <p><b>Status:</b> {data.pay_status}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="col-md-8 col-sm-6 col-xs-6" style={{"fontSize":"15px"}}><strong>{data.service}</strong></td>
                                        <td className="col-md-4 col-sm-6 col-xs-6" style={{"fontSize":"15px"}}><strong> {data.base_amount}</strong> </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="text-right">
                                            <p>
                                                <strong style={{"fontSize":"15px"}}>{data.gst_type} ({GSTPercent}): </strong>
                                            </p>
                                    
                                        </td>
                                        <td>
                                        
                                        <p>
                                            <strong style={{"fontSize":"15px"}}> {data.gst_amount} </strong>
                                        </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        
                                        <td className="text-right"><h2><strong>Total: </strong></h2></td>
                                        <td className="text-left"><h2><strong><i className="fa fa-inr"></i> {data.total_amount} /-</strong></h2></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>    
                </div>
                </div>
            </div>
        </>
    )
}

export default Component;